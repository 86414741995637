import http, { e } from './http'
import loginService from './login.service'

const userService = {  
  getAllRoles: function (){
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.get(e('identity/roles/all'), null, headers).then(r => {
      return r.data
    })
  },
  getUsers: function (offset,limit,filter,sorting) {
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    const params = new URLSearchParams();
    params.append('SkipCount', offset);
    params.append('MaxResultCount', limit);
    
    if(sorting)
      params.append('Sorting', sorting);

    if(filter)
      params.append('Filter', filter);

    return http.get(e('identity/users'), {
      params: params
    }, headers).then(r => {
      return r.data
    })
  },  
  createUser: function (data){
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.post(e('identity/users'), data, headers).then(r => {
      return r.data
    })
  },
  getUser(id){
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.get(e(`identity/users/${id}`), null, headers).then(r => {
      return r.data
    })
  },
  updateUser(id, data){
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.put(e(`identity/users/${id}`), data, headers).then(r => {
      return r.data
    })
  },
  deleteUser(id){
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.remove(e(`identity/users/${id}`), headers).then(r => {
      return r.data
    })
  },
  getUserRoles(id){
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.get(e(`identity/users/${id}/roles`), null, headers).then(r => {
      return r.data
    })
  },
  sendLastVersion(id){
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.post(e(`app/app-version/send-version/${id}`), null, headers).then(r => {
      return r.data
    })
  }
}

export default userService
