import http, { e } from './http'
import loginService from './login.service'
import {off} from "leaflet/src/dom/DomEvent";

const catalogsService = {
  getHigways: function (offset,limit,filter,sorting) {
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };
    
    const data = {
      SkipCount: offset,
      MaxResultCount: limit
    }

    if(sorting)
      data['Sorting'] = sorting;

    if(filter)
      data['Filter'] = sorting;

    return http.get(e('app/highway'), data, headers).then(r => {
      return r.data
    })
  },
  saveHighwayRelations: function(data){
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.post(e('app/highway/save-relations'), data, headers).then(r => {
      return r.data
    })
  },
  createHighway: function (data){
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.post(e('app/highway'), data, headers).then(r => {
      return r.data
    })
  },
  deleteHighway: function (highwayId){
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.remove(e(`app/highway/${highwayId}`), headers).then(r => {
      return r.data
    })
  },
  getLanes: function (offset,limit,filter,sorting) {
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    const params = new URLSearchParams();
    params.append('SkipCount', offset);
    params.append('MaxResultCount', limit);

    if(sorting)
      params.append('Sorting', sorting);

    if(filter)
      params.append('Filter', filter);

    return http.get(e('app/lane'), {
      params: params
    }, headers).then(r => {
      return r.data
    })
  },
  getLanesByHighway: function (highwayId) {
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.get(e(`app/lane/lanes-by-highway/${highwayId}`), null, headers)
      .then(r => {
      return r.data
    })
  },
  createLane: function (data){
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.post(e('app/lane'), data, headers).then(r => {
      return r.data
    })
  },
  deleteLane: function (laneId){
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.remove(e(`app/lane/${laneId}`), headers).then(r => {
      return r.data
    })
  },
  getDirections: function (offset,limit,filter,sorting) {
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    const params = new URLSearchParams();
    params.append('SkipCount', offset);
    params.append('MaxResultCount', limit);

    if(sorting)
      params.append('Sorting', sorting);

    if(filter)
      params.append('Filter', filter);

    return http.get(e('app/direction'), {
      params: params
    }, headers).then(r => {
      return r.data
    })
  },
  getDirectionsByHighway: function (highwayId) {
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.get(e(`app/direction/directions-by-highway/${highwayId}`), null, headers)
      .then(r => {
        return r.data
      })
  },
  createDirection: function (data){
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.post(e('app/direction'), data, headers).then(r => {
      return r.data
    })
  },
  deleteDirection: function (directionId){
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.remove(e(`app/direction/${directionId}`), headers).then(r => {
      return r.data
    })
  },
  getSide: function (offset,limit,filter,sorting) {
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    const params = new URLSearchParams();
    params.append('SkipCount', offset);
    params.append('MaxResultCount', limit);

    if(sorting)
      params.append('Sorting', sorting);

    if(filter)
      params.append('Filter', filter);

    return http.get(e('app/side'), {
      params: params
    }, headers).then(r => {
      return r.data
    })
  },
  getSidesByHighway: function (highwayId) {
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.get(e(`app/side/sides-by-highway/${highwayId}`), null, headers)
      .then(r => {
        return r.data
      })
  },
  createSide: function (data){
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.post(e('app/side'), data, headers).then(r => {
      return r.data
    })
  },
  deleteSide: function (sideId){
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.remove(e(`app/side/${sideId}`), headers).then(r => {
      return r.data
    })
  },
}

export default catalogsService
